export default function Footer() {
    return (
        <footer className="w-full bg-[#29335C] justify-center items-center flex pt-5 pb-5 flex-col text-white">
            <ul className="my-10 flex flex-col sm:flex-row justify-center items-center">
                <li className="text-xl hover:text-gray-300">
                    <a href="#inicio">Inicio</a>
                </li>
                <li className="text-xl my-3 sm:my-0 sm:mx-5 hover:text-gray-300">
                    <a
                        href="https://cpgoma.mitiendanube.com/productos/"
                        target="_Blank"
                        rel="noreferrer"
                    >
                        Tienda
                    </a>
                </li>
                <li className="text-xl mb-3 sm:mb-0 sm:mr-5 hover:text-gray-300">
                    <a href="#nosotros">Nosotros</a>
                </li>
                <li className="text-xl hover:text-gray-300">
                    <a href="#contacto">Contacto</a>
                </li>
            </ul>
            <img
                src="/logo.png"
                alt="Logo CP Goma"
                loading="lazy"
                className="invert w-60"
            />
            <span className="md:self-start md:ml-5 mt-10">
                Website by{" "}
                <a
                    href="https://www.linkedin.com/in/maximo-stodulski/"
                    className="font-medium hover:text-gray-300"
                >
                    Maximo Stodulsky
                </a>
            </span>
        </footer>
    );
}
