import React from "react";

import { Producto } from "./producto";

export const Conjunto = () => {
    return (
        <>
            <Producto
                img={"/mangueraAutomotor.png"}
                title={"Manguera automotor"}
            />
            <Producto
                img={"/mangueraIndustrial.png"}
                title={"Mangueras industriales"}
            />
            <Producto
                img={"/burleteEsponjoso.png"}
                title={"Burlete esponjoso"}
            />
            <Producto img={"/burleteDuro.png"} title={"Burlete duro"} />
            <Producto img={"/gomasVarias.jpg"} title={"Varios de goma"} />
            <Producto
                img={"/correaIndustrial.jpeg"}
                title={"Correas industriales"}
            />
            <Producto img={"/pisoGoma.png"} title={"Piso de goma"} />
            <Producto img={"/planchaGoma.jpeg"} title={"Goma en Plancha"} />
            <Producto img={"/transparente.jpg"} title={"Manguera Industrial PVC"} />
            <Producto img={"/Cristal.jpg"} title={"Manguera Cristal"} />
            <Producto img={"/RojaPresion.jpg"} title={"Manguera para presión"} />
            <Producto img={"/aspirapolvo.png"} title={"Manguera Aspirapolvo"} />
        </>
    );
};
