import { FaWhatsapp, FaShop, FaComputer } from "react-icons/fa6";

import "./ml.css";

export default function Ml() {
    return (
        <section className="flex flex-col sm:flex-row w-full py-16 sm:py-6 mlBgImg justify-center items-center">
            <a
                target="_blank"
                rel="noreferrer"
                href="https://wa.link/p9hkjb"
                className="flex flex-col justify-center items-center group hover:scale-105 duration-200 bg-white mlCard p-8 rounded-md border-t-4 border-[#29335C] "
            >
                <div className="bg-[#25D366] p-5 rounded-full mb-2 mlBox">
                    <FaWhatsapp className="text-5xl text-white"></FaWhatsapp>
                </div>
                <span className="text-2xl text-zinc-800 textShadowMl">
                    Whatsapp
                </span>
                <button className="mt-3 bg-[#29335C] w-32 py-1.5 text-[#ececec] rounded group-hover:bg-[#2c3866] duration-300">
                    Contactar
                </button>
            </a>

            <a
                target="_blank"
                rel="noreferrer"
                href="https://cpgoma.mitiendanube.com/productos/"
                className="flex flex-col justify-center items-center my-16 sm:mx-3 md:mx-14 lg:mx-32 group hover:scale-105 duration-200 bg-white mlCard p-6 rounded-md border-t-4 border-[#29335C]"
            >
                <div className="bg-[#29335C] p-7 rounded-full mb-2 mlBox">
                    <FaComputer className="text-6xl text-white"></FaComputer>
                </div>

                <span className=" text-3xl text-zinc-800  textShadowMl">
                    Tienda online
                </span>
                <button className="mt-3 bg-[#29335C] w-32 py-1.5 text-[#ececec] rounded group-hover:bg-[#2c3866] duration-300">
                    Comprar
                </button>
            </a>

            <a
                href="https://www.google.com/maps?ll=-34.610104,-58.558433&z=15&t=m&hl=es-419&gl=AR&mapclient=embed&cid=10169371719841469939"
                target="_blank"
                rel="noreferrer"
                className="flex flex-col justify-center items-center group hover:scale-105 duration-200 bg-white mlCard p-8 rounded-md border-t-4 border-[#29335C] "
            >
                <div className="bg-[#4f67c7] p-5 rounded-full mb-2 mlBox">
                    <FaShop className="text-5xl text-white"></FaShop>
                </div>

                <span className="text-2xl text-zinc-800  textShadowMl">
                    Local fisico
                </span>
                <button className="mt-3 bg-[#29335C] w-32 py-1.5 text-[#ececec] rounded group-hover:bg-[#2c3866] duration-300">
                    Visitar
                </button>
            </a>
        </section>
    );
}
